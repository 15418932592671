<template>
	<form @submit.prevent="submit">
		<div class="mb-3">
			<input v-model="recoveryToken"
			       id="recovery_token"
			       class="input-field"
			       type="text"
			       placeholder="Enter your recovery token"
			       required>
			<div class="mt-2">
				<small v-if="error"
				       class="form-text mt-2 font-weight-bold text-danger text-left">
					<img src="../assets/error.png" height="15" width="15" alt="Error">
					{{ error }}
				</small>
			</div>
		</div>

		<button v-bind:disabled="error"
		        type="submit"
		        class="btn btn-primary btn-block">
			<span v-if="loading" class="spinner-border spinner-border-sm"></span>
			{{ submitButtonText }}
		</button>
	</form>
</template>

<script>
import auth from "../auth";

export default {
	name: "TwoFARecoverForm",
	props: {
		authKey: {
			type: String,
			required: true
		}
	},
	computed: {
		submitButtonText() {
			return this.loading ?
				'Loading...' :
				'Continue'
		},
	},
	data() {
		return {
			recoveryToken: null,
			error: null,
			loading: false
		}
	},
	methods: {
		submit() {
			this.loading = true

			auth.recover2FA({
				key: this.authKey,
				recovery_token: this.recoveryToken
			})
				.then(data => {
					this.loading = false
					this.error = null

					this.$router.push({ name: "Login" })
				})
				.catch(error => {
					this.loading = false
					this.error = error.response.data.message
				})
		}
	}
}
</script>

<style scoped>

</style>