<template>
	<div class="container auth-form p-4">
		<div class="row">
			<div class="col-6">
				<h1 class="header-text-font text-left-color">Extra authentication is needed to continue...</h1>
				<p class="paragraph-text-font text-left-color">If you have lost your mobile device, you can try to recover access to your account using one of your backup options.</p>
			</div>
			<div class="col-1"></div>
			<div class="col-5">
				<div class="d-grid mb-3 text-center">
					<h1 class="header-text-font text-right-color">Two-Factor Authentication</h1>
					<p class="paragraph-text-font text-right-color">Enter your recovery token in order to disable 2fa and proceed to the login page.</p>
				</div>
				<two-f-a-recover-form :auth-key="$route.params.auth_key" />
			</div>
		</div>
	</div>
</template>

<script>

import TwoFARecoverForm from "../components/TwoFARecoverForm";
export default {
	name: "TwoFARecover",
	components: {TwoFARecoverForm}
}

</script>

<style>

#app {
	background: linear-gradient(
		to right,
		#141850 0%,
		#303179 55%,
		#fae5df 0%,
		#fae5df 0%
	) 0 0 / cover;
}

</style>